.GoTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: 2px solid #E1B158;
  background: #E1B158;
  right: 2rem;
  bottom: 2rem;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  z-index: 1;

  svg {
    color: black;
    font-size: 1.5rem;
  }
}
@media(min-width: 1024px) {
  .GoTop {
    right: calc(((100vw - 1024px + 3rem) / 2 * .9 ));
  }
}
