.SearchBar{
  padding: 1rem 0;
  width: 90%;
  margin: 0 auto;
  position: sticky;
  top: 0;
  background: black;
  z-index: 1;
  max-width: 1024px;

  input{
    outline: none;
    background: black;
    border: 1px solid #E1B158;
    color: white;
    padding: .5rem 2.75rem .5rem 2.25rem;
    font-size: 1.125rem;
    width: 100%;
  }

  &-icon{
    position: absolute;
    color: white;
    font-size: 1.125rem;
    top: 50%;
    left: .75rem;
    transform: translateY(-50%);
  }

  &-clear{
    padding: .0626rem .25rem;
    background: white;
    position: absolute;
    color: white;
    font-size: 1.125rem;
    top: 50%;
    right: .75rem;
    transform: translateY(-50%);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      color: black;
    }
  }
}
