.Product {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  position: relative;
  padding: 0 1rem;
  width: 100%;

  &-content {
    text-align: left;

    &-title {
      font-size: 1rem;
      font-weight: 500;
      margin: 5px 0 0;
      color: white;
      line-height: 1.5rem;
      display: grid;
      align-items: start;
      column-gap: .5rem;
    }

    &-description {
      color: #E1B158;
      margin: .25rem 0 0;
      overflow-wrap: anywhere;
      font-size: 14px;
      line-height: 1.3;
    }
  }

  &-pricing {
    margin-left: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    padding: .25rem .5rem;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    color: black;
    background: #fff;
    border: 1px solid black;
    justify-self: flex-end;
  }

  &-image {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    object-fit: cover;
  }
}
