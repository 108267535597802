.Menu {
  position: sticky;
  top: 4rem;
  background: black;
  z-index: 1;
  padding: 0;
  max-width: 1024px;
  width: 90%;
  margin: 0 auto;

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 1.5rem;
    padding: 1rem 0;
    align-items: start;

    li {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      margin: 0 auto;
      flex-direction: column;

      .Menu-item-icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid #E1B158;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;

        svg {
          color: white;
          font-size: 1.75rem;
        }

        &--active {
          background: #E1B158;

          svg {
            color: black;
          }
        }
      }

      img {
        width: 100%;
      }

      span {
        font-size: 0.625rem;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        color: white;
        margin-top: 15px;
      }
    }
  }
}
