.SinTaccIcon {
  background: #E1B158;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  color: black;
  position: relative;
  display: flex;

  svg{
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &-wheat{
    font-size: .75rem;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &-ban{
    width: 100%;
    height: 1px;
    background: black;
    display: block;
    transform: translateX(-50%) rotate(-45deg);
    top: 50%;
    left: 50%;
    position: absolute;
  }
}
