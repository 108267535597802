.HomePage {
  text-align: center;

  .section {
    &-title {
      color: black;
      background: #E1B158;
      text-align: left;
      position: relative;
      padding: .5rem .5rem .5rem .75rem;
      display: table;
      text-transform: uppercase;
      font-size: 1.5rem;
      line-height: 1;
      margin: 2rem 0 .5rem;
      font-weight: 900;

      &:after {
        position: absolute;
        width: 0;
        height: 0;
        content: '';
        border: 1rem solid transparent;
        border-bottom-color: #E1B158;
        right: -1rem;
        top: -1rem;
        transform: rotate(-45deg);
      }

      &:before {
        position: absolute;
        width: 0;
        height: 0;
        content: '';
        border: 1rem solid transparent;
        border-top-color: #E1B158;
        right: -1rem;
        bottom: -1rem;
        transform: rotate(45deg);
      }
    }
  }

  .ProductGrid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: .5rem 1rem;
  }

  .SinTaccBanner{
    margin: 0 auto;
    display: grid;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    font-weight: 900;
    padding: 2rem 1.5rem;
    position: sticky;
    top: 200px;
    background: black;
    z-index: 1;
    max-width: 1024px;
    width: 90%;
    grid-template-columns: 1.5rem auto;

    &:before{
      content: '';
      top: 1rem;
      bottom: 1rem;
      position: absolute;
      border: 2px solid #E1B158;
      width: 100%;
    }
    span{
      color: white;
    }
  }
}

@media(max-width: 768px) {
  .HomePage {

    .ProductGrid {
      grid-template-columns: 1fr;
    }
  }
}
