.Header {
  padding: 2rem 0 1rem;
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 0;
    opacity: 0.2;
    background-image: url("/img/bg2.png");
    background-size: cover;
    background-position: center;
  }

  &:before {
    content: "";
    width: 100%;
    height: 20%;
    left: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(0deg, black, transparent);
    z-index: 1;
  }

  img {
    height: 100%;
    max-height: 200px;
    max-width: 80%;
    z-index: 1;
    position: inherit;
  }

  &-info {
    display: flex;
    margin: 2rem auto 0;
    max-width: 1024px;
    width: 90%;
    justify-content: center;
    gap: .25rem 2rem;
    position: inherit;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;

    p {
      padding: .5rem 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: white;
        text-decoration: none;
        svg {
          color: #E1B158;
          margin-right: 0.5rem;
        }
      }
    }
  }
}


@media(max-width: 768px) {
  .Header {
    &-info {
      grid-template-columns: 1fr;
    }
  }
}
