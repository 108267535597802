.Footer{
  margin-top: 2rem;
  padding-bottom: 2rem;
  p{
    color: white;
    margin: .75rem 0 0;
    a {
      color: #E1B158;
      text-decoration: none;
    }
  }
}
