@import url('https://fonts.cdnfonts.com/css/creato-display');
@import 'reset-css';

body {
    margin: 0;
    font-family: 'Creato Display', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
}
