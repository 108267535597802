.ProductCategoryList{
  padding-bottom: 2rem;
  max-width: 1024px;
  width: 90%;
  margin: 0 auto;

  p{
    a {
      color: #E1B158;
      text-decoration: none;
    }
  }
}
